// src/contexts/AuthContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import apiService from "../services/apiService";
import { jwtDecode } from "jwt-decode";
import { useProfile } from "../context/ProfileContext";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const getToken = () =>  localStorage.getItem("playertoken");
  const isTokenExpired = () => {
    const token = getToken();
    if (!token) return true;

    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Get current time in seconds
      if (decoded.exp < currentTime) {
        // Token is expired
        return true;
      }
      return false;
    } catch (error) {
      // If there's any error decoding the token, consider it invalid/expired
      return true;
    }
  };
  const getUserIdFromToken = useCallback(() => {
    const playertoken = getToken();
    if (playertoken) {
      const decoded = jwtDecode(playertoken);
      // Check token expiry
      if (isTokenExpired) {
        return null; // Token has expired
      }
      return (
        decoded.placesauthId ||
        decoded.adminId ||
        decoded.playerId ||
        decoded.partnerId
      ); // Adjust the key based on your token payload structure
    }
    return null;
  }, []);
  const [auth, setAuth] = useState({
    playertoken: localStorage.getItem("playertoken") || "",
    playeremail: localStorage.getItem("playeremail") || "",
    isAuthenticated: !isTokenExpired(),
    userId: getUserIdFromToken(),
  });


  const { refreshProfileData } = useProfile();
 

  useEffect(() => {
    const fetchUserData = async () => {
      if (!auth.playertoken) return;
      apiService.setAuthToken(auth.playertoken);
      auth.userId = getUserIdFromToken();

    };

    fetchUserData();
  }, [auth, getUserIdFromToken]);
  const login = useCallback(
    (playertoken, playeremail) => {
      localStorage.setItem("playertoken", playertoken);
      localStorage.setItem("playeremail", playeremail);
      setAuth({
        playertoken,
        playeremail:playeremail,
        isAuthenticated: true,
        userId: getUserIdFromToken(),
      });
      apiService.setAuthToken(playertoken);
      refreshProfileData();
    },
    [getUserIdFromToken, refreshProfileData]
  );

  const logout = useCallback(async () => {
    localStorage.removeItem("playertoken");
    localStorage.removeItem("playeremail");
    setAuth({ playertoken: "", playeremail: "", isAuthenticated: false, userId: null });
    apiService.setAuthToken(null);
    await apiService.logout();
    // Reset notifications on logout
    // setNotifications(defaultNotifications);
  }, []);

  const contextValue = useMemo(
    () => ({
      auth,
      login,
      logout,
      // notifications,
      // addNotification,
    }),
    [auth, login, logout]
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default React.memo(AuthProvider);
