import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

const API_BASE_URL = "https://backend.botolaa.com/api/player";
const API_BASE_URL2 = "https://backend.botolaa.com/api";
//const API_BASE_URL = "http://localhost:3006/api/player";
//const API_BASE_URL2 = "http://localhost:3006/api";
const handleSuccess = (message) => {
  toast.success(message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const handleError = (error) => {
  if (error.response) {
    toast.error(` ${error.response.data || error.response.statusText}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (error.request) {
    toast.error(" No response received from server.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else if (error.message) {
    toast.error(` ${error.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  } else {
    toast.error(` ${error}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  }
  throw error;
};
// Get the playertoken from localStorage or any other secure place you store it
const getToken = () => {
  return localStorage.getItem("playertoken");
};
// Function to check if the token is expired
const isTokenExpired = () => {
  const token = getToken();
  if (!token) return true;

  try {
    const decoded = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Get current time in seconds
    if (decoded.exp < currentTime) {
      // Token is expired
      return true;
    }
    return false;
  } catch (error) {
    // If there's any error decoding the token, consider it invalid/expired
    return true;
  }
};

// Function to handle token expiration
const handleTokenExpiration = () => {
  if (isTokenExpired()) {
    window.location.href = "/login"; // Redirect user to login page
    localStorage.removeItem("playertoken"); // Remove token from localStorage
    handleError("Token Expired, please log in again");
  }
};
const apiService = {
  setAuthToken: (playertoken) => {
    if (playertoken) {
      axios.defaults.headers.common["Authorization"] = `Bearer ${playertoken}`;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  },
  generateAndSendOtp: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/generateAndSendOtp`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  generateAndSendOtpAuth: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/generateAndSendOtpAuth`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  // Admin Authentication
  register: async (data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/register`, data);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  login: async (data) => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/login`, data);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  registerWithGoogle: async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/auth/google`);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  logout: async () => {
    try {
      const response = await axios.post(`${API_BASE_URL}/auth/logout`);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  forgotPasswordemail: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/forgot-password-email`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  resetPasswordemail: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/reset-password-email`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  forgotPasswordphone: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/forgot-password-phone`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  resetPasswordphone: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/reset-password-phone`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  checkOTPValidation: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/checkOTPValidation`,
        data
      );
      handleSuccess(response.data.message);
      return response;
    } catch (error) {
      handleError(error.response.data.message);
    }
  },

  verifyEmailOTP: async (data) => {
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/verify-email-otp`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  changeemail: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/change-email`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error.response.data);
    }
  },
  confirmchangeemail: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/confirm-change-email`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  changepassword: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/change-password`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  changephone: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/change-phone`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error.response.data);
    }
  },
  confirmchangephone: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL}/auth/confirm-change-phone`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error.response.data);
    }
  },

  getStatusById: async () => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/idverificationfiles/getStatusById`
      );
      return response;
    } catch (error) {}
  },

  idverificationfiles: async (data) => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/idverificationfiles/upload`,
        data
      );
      handleSuccess(response.message);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  //place
  getMyProfileById: async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/list/getMyProfileById`
      );
      return response;
    } catch (error) {}
  },

  updateProfile: async (data, id) => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.put(
        `${API_BASE_URL}/profile/update/${id}`,
        data
      );
      handleSuccess(response.data.message);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  updateExperienceFiles: async (data, id) => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.put(
        `${API_BASE_URL}/profile/updateExperienceFiles/${id}`,
        data
      );
      handleSuccess(response.data.message);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getAllCountries: async () => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/CountriesWithCities/list`
      );
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  getChampionDetailsById: async (id) => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/list-getChampionDetailsById/${id}`
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  getChampionsWithDatails: async (page = 1, limit = 10, filter = "All") => {
    handleTokenExpiration(); // Check if the token is expired
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/list-getChampionsWithDatails`,
        {
          params: {
            page: page,
            limit: limit,
            filter:filter
          },
        }
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  listChampions: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL}/profile/list`);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getChampionById: async (id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champions/${id}`
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  listChampionPrizes: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion-prizes/list`
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getChampionPrizeById: async (id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion-prizes/${id}`
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  // Matches
  getMatchesByPlayerId: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      // const userId = getUserIdFromToken();
      const response = await axios.get(`${API_BASE_URL}/profile/player/12`);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  // getMatchesByPlaceId: async () => {
  //   try {
  //     // const userId = getUserIdFromToken();
  //     const response = await axios.get(
  //       `${API_BASE_URL}/profile/list-getMatchesByPlacesAuth_Id`
  //     );
  //     handleSuccess(response.data);
  //     return response;
  //   } catch (error) {
  //     handleError(error);
  //   }
  // },
  getMatchById: async (id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL}/profile/matches/${id}`);
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  // getChampionMatchesById: async (id) => {
  //   try {
  //     const response = await axios.get(
  //       `${API_BASE_URL}/profile/list-ChampionMatchesById/${id}`
  //     );
  //     handleSuccess(response.data);
  //     return response;
  //   } catch (error) {
  //     handleError(error);
  //   }
  // },
  // Fetch matches related to a specific champion_id

  // Fetch places
  getMyPlaces: async (currentPage, limit, filter) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL}/profile/myplaces`, {
        params: {
          page: currentPage,
          limit: limit,
          filter: filter,
        },
      });
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getPlaces: async (currentPage, limit, filter) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL}/profile/places`, {
        params: {
          page: currentPage,
          limit: limit,
          filter: filter,
        },
      });
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getMatchesForChampion: async (championId, page, itemsPerPage) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion/${championId}/matches`,
        {
          params: {
            page: page,
            limit: itemsPerPage,
          },
        }
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  // Fetch places related to a specific champion_id
  getPlacesForChampion: async (championId, currentPage, limit) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion/${championId}/places`,
        {
          params: {
            page: currentPage,
            limit: limit,
          },
        }
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  // Fetch partners related to a specific champion_id
  getPartnersForChampion: async (championId, currentPage, limit) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion/${championId}/partners`,
        {
          params: {
            page: currentPage,
            limit: limit,
          },
        }
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  // Fetch teams related to a specific champion_id and accepted status
  getAcceptedTeamsForChampion: async (championId, currentPage, limit) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/champion/${championId}/teams/accepted`,
        {
          params: {
            page: currentPage,
            limit: limit,
          },
        }
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  // Team-related API functions
  searchplayers: async (playerId) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/search/searchplayers/${playerId || ""}`
      ); // Pass the ID as a URL parameter or leave it empty for all places
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  searchplaces: async (placeId) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/search/searchplaces/${placeId || ""}`
      ); // Pass the ID as a URL parameter or leave it empty for all places
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  createTeam: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(`${API_BASE_URL2}/teams/create`, data);
      handleSuccess("Team created successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  listTeams: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL2}/teams/list`);
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  listAllTeams: async (currentPage, limit) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL2}/teams/list-all`, {
        params: {
          page: currentPage,
          limit: limit,
        },
      });
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  MyJoinRequests: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/my-join-requests`
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },
  sendJoinRequest: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/join-request`,
        data
      );
      handleSuccess("Join Request sent successfully");
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  listMyTeams: async (page = 1, limit = 10) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL}/profile/list/listMyTeams`,
        {
          params: { page: page, limit: limit }, // Send pagination parameters
        }
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching my teams:", error);
      // handleError(error); // Uncomment to handle the error in your specific way
    }
  },

  getTeamById: async (team_id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/list-id/${team_id}`
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  insertInterestedUserToChampion: async (champion_id, player_id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/insertInterestedUserToChampion`,
        { champion_id, player_id }
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },
  updateTeamAndMembers: async (team_id, data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.put(
        `${API_BASE_URL2}/teams/updateTeamAndMembers/${team_id}`,
        data
      );
      handleSuccess("Team updated successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  updateTeam: async (team_id, data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.put(
        `${API_BASE_URL2}/teams/update/${team_id}`,
        data
      );
      handleSuccess("Team updated successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  deleteTeam: async (team_id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.delete(
        `${API_BASE_URL2}/teams/delete/${team_id}`
      );
      handleSuccess("Team deleted successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  sendInvitation: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(`${API_BASE_URL2}/teams/invite`, data);
      handleSuccess("Invitation sent successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  listPlayerInvitations: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL2}/teams/invitations`);
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  respondToInvitation: async (invitation_id, data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/respond-invitation/${invitation_id}`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  listTeamInvitationsResponses: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/team-invitation-responses`
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  listAllTeamsForJoining: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL2}/teams/list-all-teams`);
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  // sendJoinRequest: async (data) => {
  //   try {
  //     const response = await axios.post(
  //       `${API_BASE_URL2}/teams/join-request`,
  //       data
  //     );
  //     handleSuccess("Join request sent successfully");
  //     return response;
  //   } catch (error) {
  //     handleError(error);
  //   }
  // },

  listTeamJoinRequests: async (team_id) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams-join-requests/${team_id}`
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },
  listJoinRequestsForCreatedTeams: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/listJoinRequestsForCreatedTeams`
      );
      return response.data;
    } catch (error) {
      // handleError(error);
    }
  },

  respondToJoinRequest: async (joining_id, data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/respond-join-request/${joining_id}`,
        data
      );
      handleSuccess(response.data);
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  listPlayerJoinRequests: async () => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(
        `${API_BASE_URL2}/teams/my-join-requests`
      );
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },
  notifications: async (currentPage, limit) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.get(`${API_BASE_URL2}/notifications/list`, {
        params: {
          page: currentPage,
          limit: limit,
        },
      });
      return response.data;
    } catch (error) {
      handleError(error);
    }
  },

  enterPlayerData: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/enter-player-data`,
        data
      );
      handleSuccess("Player data entered successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  updatePlayerData: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.put(
        `${API_BASE_URL2}/teams/update-player-data`,
        data
      );
      handleSuccess("Player data updated successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  deletePlayerData: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.delete(
        `${API_BASE_URL2}/teams/delete-player-data`,
        { data }
      );
      handleSuccess("Player data deleted successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  interestToChampion: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/subscription/interestToChampion`,
        data
      );
      handleSuccess(
        "Interest submitted successfully, You will be up to date with this champion news"
      );
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  submitSubscriptionRequest: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/submit-subscription`,
        data
      );
      handleSuccess("Subscription request submitted successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },
  getAvailableClothingDesign: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/getAvailableClothingDesign-subscription`,
        data
      );
      return response;
    } catch (error) {
      handleError(error.response.data.message);
    }
  },
  selectClothingDesign: async (data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/select-clothing-design`,
        data
      );
      handleSuccess("Clothing design selected successfully");
      return response;
    } catch (error) {
      handleError(error);
    }
  },

  rejectSubscriptionRequest: async (request_id, data) => {
    handleTokenExpiration(); // Check if the token is expiredr
    try {
      const response = await axios.post(
        `${API_BASE_URL2}/teams/reject-subscription/${request_id}`,
        data
      );
      handleSuccess("Subscription request rejected");
      return response;
    } catch (error) {
      handleError(error);
    }
  },
};
export { getToken };
export default apiService;
